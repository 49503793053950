<template>
    <div style="background:#F7F7F7">
        <img v-if="info.product.logoUrl" :src="info.product.logoUrl" @click="showImg=true" style="width:100%;height: 200px;"/>
        <div class="infoCenter">
            <h3 class="indexTitle">
                {{info.product.name}}
            </h3>
            <van-cell>
            <template #title>
                <div>
                    <span v-if="info.product.isPermitRefund" class="refun">此产品可退款</span>
                    <span  v-if="info.product.isPermitRefund" class="refunPrice">退票手续费：0元</span>
                </div>
                <p style="width: 100%;; color: #ff5345; font-size: 25px">￥{{ info.product.salePrice }}
                <span v-if="info.product.originalPrice" style="text-decoration: line-through; color: #999999; font-size: 15px;">
                    ￥{{ info.product.originalPrice }}</span>
                </p>
            </template>
            </van-cell>
        </div>
        <div style="" class="mpxuzhi">
            <h3>购票须知</h3>
            <p v-if="info.product.productExtend.buyNotice" v-html="info.product.productExtend.buyNotice"></p>
            <div v-else style="color: #969799;text-align:center;padding: 5px;">暂无说明</div>
        </div>
        <van-row class="sum">
            <div style="width: 95%;margin: 0 auto;color: rgb(0 0 0 / 70%);">
                小结：<span style="color: #ff5345;font-weight: bold;">{{'￥ '+info.product.salePrice +'元'}}</span> 
                <span style="float: right;" class="sub" @click="toAddOrder">立即预定</span> 
            </div>
        </van-row>
        <van-dialog v-model="showImg" :title="info.product.name" show-cancel-button>
            <img :src="info.product.logoUrl" style="width:100%;"/>
        </van-dialog>
    </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: 'xyzhi',
  data(){
        return {
            productId:'',
            info:{},
            showImg:false
        }
  },
  created(){
      this.productId = this.$route.query.id;
      this.getInfo()
  },
  methods:{
      getInfo(){
        this.$http({
            method: "post",
            url: `${this.apis.productDetail}?productId=${this.productId}`,
        }).then((res) => {
            if (res.code == 200) {
                this.info = res
            }
        })
      },
      /**
         * toAddOrder
         * 加入购物车
         */
        toAddOrder(){
            let product = this.info.product.id
            //1校验
            this.$http({
                method: "post",
                url: `${this.apis.addValid}`,
                params: {
                    productId: product,
                    count:1,
                    isSales:0
                }
            }).then((res) => {
                if (res.code && res.code == 200) {
                    //2加入购物车
                    return this.$http({
                        method: "post",
                        url: `${this.apis.add}`,
                        params: {
                            productId: product,
                            count:1,
                            isSales:0
                        }
                    })
                }else{
                    //不允许购买
                    Toast({
                        message: res.message,
                        icon: 'fail',
                    });
                    this.clearCart();
                    return
                }
            }).then((res) => {
                console.log(res)
                if(res.code == 200){
                    //3 确认购物车 confirm
                    this.toConfirm(res.cartId,this.info.product.isAppointment)
                }
            })
        },
        /**
         * 检查是否可购买
         */
        toConfirm(cartId,isAppointment) {
            this.$http({
                method: "post",
                url: `${this.apis.confirm}`,
                params: { 'cartIds': cartId },
            }).then((res) => {
                console.log(res);
                if(res.code && res.code == 200){
                    // this.carts = res.data.productList;
                    this.$store.commit('saveOrders',res.data) //存给下单页面
                    localStorage.setItem('orders',JSON.stringify(res.data))
                    this.$router.push({path: '/addOrder',query:{isAppointment}});//, query: this.info
                }else{
                    //不允许购买
                    Toast({
                        message: res.message,
                        icon: 'fail',
                    });
                    this.clearCart();
                    return
                }
                
            });
        },
        clearCart(){
            this.$http({
                method: "post",
                url: `${this.apis.cleanCart}`,
            }).then((res) => {
                localStorage.removeItem("orders")
                console.log(res)
            })
        },
  }
}
</script>
<style  scoped>
.indexTitle{
    margin: 8px 10px;
    color: #333333;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .infoCenter{
    border-radius: 14px;
    box-shadow: 0 0 4px #dcdee0;
    overflow: hidden;
    position: relative;
    top: -15px;
    background: white;
  }
.sum{
    position: fixed;
    z-index: 99;
    bottom: 0;
    width: 100%;
    background-color: #fdfbfb;
    height: 55px;
    line-height: 55px;
}
.sub{
    width: 85px;
    background-color: #ff5345;
    box-shadow: 0 0 10px #dcdee0;
    color: white;
    text-align: center;
    border-radius: 15px;
    padding: 5px 10px;
    line-height: 25px;
    margin-top: 10px;
}
.refun{
  color: #FA7134;
  border: 1px solid #ff976a;
  border-radius: 15px;
  padding: 2px 5px;
}
.refunPrice{
  color: #FA7134;
  border: 1px solid #ff976a;
  border-radius: 15px;
  padding: 2px 5px;margin-left: 5px;
}
.mpxuzhi{
    padding: 15px;
    border-radius: 14px 14px 0px 0px;
    overflow: hidden;
    background: white;
    box-shadow: 0 0 0 #dcdee0;
}
</style>